import {FC, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import useCurriculumDetail from '../../Hooks/useCurriculumDetail';
import UserSearch from '../Organisms/UserSearch';
import useDateJp from '../../Hooks/useDateJp';
import {User} from '../../Types/User';
import {Alert, Avatar, Box, Grid, Paper, Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';
import SecondButton from '../atom/Buttons/SecondButton';
import DefaultButton from '../atom/Buttons/DefaultButton';
import {MessageContext} from '../MessageContext';
import BackdropLodding from '../comm/BackDrop';

type IBookDetailParam =
  {
    id: string
  }
const SBox = styled(Paper)(({theme}) => ({
  textAlign: 'center',
  padding: 20,
  margin: '1em 0'
}));


// type PageProps = {} & RouteComponentProps<{id: string}>;
const CurriculumDetail: FC = () => {
  const {
    curriculum,
    onUsers,
    unUsers,
    setResonse,
    setCurriculumId,
    fetchRequest,
    handlePersonAdd,
    handlePersonCancel,
    systemMessage,
    loading
  } = useCurriculumDetail()

  const [serchUsers, setSearchUsers] = useState<User[]>(unUsers ? unUsers : [])
  const messageContext = useContext(MessageContext)

  const {dateTimeJp, dateJp} = useDateJp()
  const {id} = useParams() as IBookDetailParam
  const navigate = useNavigate();
  const handleSelectUser = (userId: number) => {
    handlePersonAdd(userId)
  }
  const handGoToBack = () => {
    if (curriculum) {
      let date = new Date(curriculum.lecture_date)

      navigate(`/books/${date.getFullYear()}/${date.getMonth() + 1}`)
    }
  }


  const isReserve = (): boolean => {
    if (curriculum) {
      const lectureDate = new Date(curriculum.lecture_date)
      const today = new Date()
      if (lectureDate >= today) return true
    }

    return false
  }


  useEffect(() => {
    fetchRequest(id)
    setCurriculumId(id)
  }, [])
  useEffect(() => {
    console.log('useF')
    setSearchUsers(unUsers ? unUsers : [])
  }, [onUsers])


  useEffect(() => {
    messageContext.handleSetMessage(systemMessage?.text ? systemMessage.text : '')
  }, [systemMessage])


  useEffect(() => {
    if (loading) messageContext.handleSetMessage('')

  }, [loading])
  if (loading) return <><BackdropLodding showBackdrop={loading}/></>

  return (
    <>
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant={'h5'}>{curriculum?.lecture_date && dateJp(curriculum.lecture_date.toString())} {curriculum?.name} 予約状況</Typography>
          </Grid>
          <Grid item xs={2} justifyContent="flex-end">
            <p style={{textAlign: 'right'}}>{curriculum?.lecture_date &&
            <SecondButton onClick={handGoToBack} label="戻る"/>}</p>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{marginBottom: '5en'}}>
        <Typography variant="h6" sx={{mb: 2}}>予約人数{onUsers?.length}人</Typography>
        {onUsers?.length === 0 && (
          <Alert sx={{mt: 2, mb: 4}} severity="info">予約はありませんでした。
          </Alert>
        )}
        <Grid container justifyContent="flex-start" spacing={{xs: 1, md: 1}}>
          {onUsers?.map((user, index) => (
            <Grid item key={index} xs={2}>
              <SBox>
<Stack spacing={2}>
                <Typography sx={{marginBottom: '0.4em'}}><Avatar>{index + 1}</Avatar>.{user.name}</Typography>
                <Typography>{user.book_created_at && dateTimeJp(user.book_created_at)}</Typography>
                {user.book_id && isReserve() && (

                  <DefaultButton
                   onClick={event => user.book_id && handlePersonCancel(user.book_id)} label="キャンセル"/>
                )}</Stack>
              </SBox>
            </Grid>

            // </Link> |</p>
          ))}
        </Grid>
      </Box>
      {isReserve() && (
        <>
        <Typography variant="h6" sx={{mt: 4}}>未予約</Typography>
      {serchUsers && <UserSearch users={serchUsers} usersUpdate={setSearchUsers}
        button={{label: '追加', func: handleSelectUser}}/>}
      </>
        )}
    </>)
}
export default CurriculumDetail