import {useMemo} from 'react';

const useDateJp = () => {

  const dateTimeJp = (date:string) => {
    const datetime = new Date(date)
    return  (datetime.getMonth() + 1) + "月" + datetime.getDate() + "日　" + datetime.getHours() + "時" + datetime.getMinutes() +    "分"
  }
  const dateJp = (date:string) => {
    const datetime = new Date(date)
    return  (datetime.getMonth() + 1) + "月" + datetime.getDate() + "日　"
  }
  return {dateTimeJp,dateJp}
}
export default useDateJp

type memoProp ={
  dateText : string
}


