import {Dispatch, SetStateAction, useState} from 'react';
import http from '../Stores/http';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {ICurriculum, ICurritulums, ILectureMaster, SystemMessage} from '../Types/Response';
import {SelectChangeEvent} from '@mui/material';
import Config from "../Types/config";
import {TCurriculumFile} from "../Components/Organisms/SpecialCurritulumEdit";

interface IResponse {
  lecture_master: Array<ILectureMaster> | undefined;
  curriculums: Array<ICurriculum>,
  error: AxiosError | null;
  loading: boolean;
  systemMessage:undefined|SystemMessage
}

interface IPostCurriculum {
  lecture_date: string,
  lecture_id: number,
  is_time: number,
  title: string,
  url: string,
}


interface IPostCurritulums {
  curriculums: IPostCurriculum[]
}

const useFetchcurriculums = () => {
  const [response, setResonse] = useState<IResponse>({
    lecture_master: undefined,
    curriculums: [],
    error: null,
    loading: false,
    systemMessage:undefined

  })
  const [calendarDate, setCalendaqrDate] = useState<Date>(new Date())


  const handleCalendarUP = () => {
    let carrentDate = calendarDate
    carrentDate.setMonth(carrentDate.getMonth() + 1)
    setCalendaqrDate(carrentDate)
    fetchRequest()
  }
  const handleCalendarDown = () => {
    let carrentDate = calendarDate
    carrentDate.setMonth(carrentDate.getMonth() - 1)
    setCalendaqrDate(carrentDate)
    fetchRequest()
  }

  const handleChange = (key: number, event: SelectChangeEvent, col: 'title' | 'lecture_id' = 'lecture_id') => {
    const responseNw = response.curriculums?.map((curriculun, index) => (
        index === key ? Object.assign(curriculun, {[col]: event.target.value}) : curriculun
    ))
    setResonse(prevState => ({...prevState, response: responseNw}))
  }
  const handlePostRequest = () => {
    if (response.curriculums !== undefined) {
      setResonse(prevState => ({...prevState, loading: true}))
      let post: IPostCurritulums = {curriculums: []}

      // eslint-disable-next-line array-callback-return
      response.curriculums?.map(value => {
        post.curriculums.push({...value, lecture_date: formatDate(value.lecture_date),is_time:value.is_time})
      })
      // http.post<ICurritulums<string> | null>('curriculums/update',{curriculums:[{id:1,lecture_date:'2021-12-29',lecture_id:0}]})
      http.post<ICurritulums>('curriculums/update', post)
          .then((response) => {
            setResonse(prevState => ({...prevState, curriculums:response.data.lectures,loading:false,systemMessage:{text:'カリキュラムを保存しました。',isDelete:true}}))
            　fetchRequest()
          })
          .catch((error: AxiosError) => {
            console.log(error)
            setResonse((prevState) => ({...prevState, error: error, loading: false}))

          })

    }
  }
  type TUploadDelete = { message: string }
  const uploadDelete = (curriculum_id: number) => {
    http.post<TUploadDelete>('curriculums/uploadDelete', {curriculum_id: curriculum_id})
        .then((response) => {
          setResonse(prevState => ({...prevState,systemMessage:{text:"カリキュラムファイルを削除しました。",isDelete:true}}))
        })
        .catch((error: AxiosError) => {
          console.log(error)
          setResonse((prevState) => ({...prevState, error: error, loading: false}))

        })

  }

  type TCurriculumFileResponse = {
    file_name: string
    url: string
  }


  const handleFileIploadRequest = (curriculum_id: number, fileList: FileList, editState: Dispatch<SetStateAction<TCurriculumFile>>) => {
    const files = fileList ? fileList : null
    if (fileList) {
      const tokken = localStorage.getItem('user')
      const requestConfig: AxiosRequestConfig = {
        baseURL: Config.apiIrl,
        headers: {"Authorization": `Bearer ${tokken}`, "content-type": "multipart/form-data"}
      }

      const client = axios.create(requestConfig)
      const post = new FormData()
      const file = fileList.item(0)
      post.append('file', file ? file : "", file ? file.name : "noUpload")
      post.append('curriculum_id', curriculum_id.toString())
      setResonse(prevState => ({...prevState, loading: true}))

      client.post<TCurriculumFileResponse | null>('curriculums/fileupload', post)
          .then((response) => {
            console.log(response)
            editState({
              fileName: response.data ? response.data.file_name : "",
              url: response.data ? response.data.url : ""
            })
            setResonse(prevState => ({...prevState,systemMessage:{text:"カリキュラムファイルをアップロードしました。",isDelete:true}}))
          })
          .catch((error: AxiosError) => {
            console.log(error)
            setResonse((prevState) => ({...prevState, error: error, loading: false}))

          })

    }
  }
  const formatDate = (current_datetime: Date) => {
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate();
    // console.log(formatted_date)
    return formatted_date;
  }

  const fetchRequest = () => {
    setResonse(prevState => ({...prevState, loading: true}))
    let month = calendarDate.getMonth() + 1
    http.get<ICurritulums | null>('curriculums/' + calendarDate.getFullYear() + '/' + month)
        .then((response) => {
          let currilumus: Array<ICurriculum> = []
          response.data?.lectures?.map(value => {
            // console.log(value.lecture_date)
            // console.log(new Date(value.lecture_date))
            // console.log(currilumus)
            currilumus.push({...value, lecture_date: new Date(value.lecture_date)})
          })
          console.log(response.data?.lectures)
          console.log(currilumus)
          setResonse({
            lecture_master: response.data?.lecture_master,
            curriculums: currilumus,
            error: null,
            loading: false,
            systemMessage:{text:'カリキュラムを読み込みました。',isDelete:true}
          })
        })
        .catch((error: AxiosError) => {
          console.log(error)
          // setResonse({... error:error,loading:false})

        })
  }
  return {
    ...response,
    fetchRequest,
    setResonse,
    handleFileIploadRequest,
    uploadDelete,
    calendarDate,
    handleChange,
    handlePostRequest,
    handleCalendarUP,
    handleCalendarDown
  }
};
export default useFetchcurriculums;