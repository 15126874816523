import {makeStyles, styled} from '@mui/styles';
import {ButtonProps, TableCell, Theme as GTheme} from '@mui/material'
import {blue, green, lightGreen, orange, red, yellow} from '@mui/material/colors';
import {FC} from 'react';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import Theme from '../ThemeProvider'

const SCellDay = styled(TableCell)(({theme}) => ({
  textAlign: 'center',
  width: '14.5%',
  verticalAlign: 'top',
  border: '1px solid #eee',
  overflow: "hidden",

  padding: '2enm0.5em',
  "@media screen and (max-width: 600px) ": {
    border: '1px solid #eee',
    display: 'flex',
    width: '90%',
  }
}))
type StlyeProps = {
  bookCountChar: string

}
const useStyles = makeStyles<GTheme, StlyeProps>({
  custom: {
    background: (props) => ((props.bookCountChar === 'busy') ? orange['50'] : (props.bookCountChar === 'open') ? yellow['50'] : '#fff'),
    // color:(props) => ((props.bookCountChar === 'sun') ? red['50'] : (props.bookCountChar === 'sun') ? blue['50'] : green['600'])
  },
  dispNone:{
    [Theme.breakpoints.up('xs')]: {
      display: "cell"
    }
  },
  dispBlock:{
    display:"cell"
  }
})
type CellProps = Pick<TableCellProps,'sx'>
interface Props extends CellProps  {
  mobileDisplay:'block'|'none'
  bookCount?: number
}

const CellDay: FC<Props> = ({bookCount = 0,mobileDisplay, sx, children}) => {
  const selectClasses = (): StlyeProps => {
    if (bookCount > 10) return {bookCountChar: 'busy'}
    if (bookCount > 5) return {bookCountChar: 'open'}
    return {bookCountChar: 'default'}
  }
  const classes = useStyles(selectClasses())

  return (
      <><SCellDay className={`${classes.custom} ${mobileDisplay === 'none' ? classes.dispNone : classes.dispBlock}`}>{children}</SCellDay></>
  )
}
export default CellDay