import {FC, memo} from 'react';
import {makeStyles, styled} from '@mui/styles';
import {Theme, Typography} from '@mui/material';
import {blue, green, red} from '@mui/material/colors';
import Config from '../../Types/config';


const SDayHead = styled(Typography)(({theme}) => ({
  margin:"0 0 0.5em 0",
  borderRadius:10,
  padding:'5px',
}))

type StlyeProps ={
  day:'sun'|'sat'|'default'
}
const useStyles = makeStyles<Theme, StlyeProps>({
  custom: {
    background:(props) => ((props.day === 'sun') ? red['A100'] : (props.day === 'sat') ? blue['A100'] : green['50']),
    color:(props) => ((props.day === 'sun') ? red['50'] : (props.day === 'sat') ? blue['50'] : green['600'])
  },
})

type Props ={
  date:Date
}
const CellDateHead:FC<Props> = ({date}) => {
  const dayOfWeekStr = Config.weeks
  const selectClasses = ():StlyeProps =>{
    switch (date.getDay()) {
      case 0:
        return { day:'sun'}
        break
      case 6:
        return { day:'sat'}
        break
      default:
        return { day:'default'}
    }
  }
  const classes = useStyles(selectClasses())


  return (
  <>
    <SDayHead className={classes.custom}>
      {date.getDate()}({dayOfWeekStr[date.getDay()]})
    </SDayHead>
  </>
  )
}

export default CellDateHead