import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, Controller, SubmitHandler} from "react-hook-form";
import {FC, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useFetchUserDetail from '../../Hooks/useFetchUserDetail';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  Select,
  Stack, Switch,
  TextField,
  Typography
} from '@mui/material';
import {TStudent} from "../../Types/User";
import * as yup from "yup"
import DefaultButton from "../atom/Buttons/DefaultButton";
import React from "react";
import {useNavigate} from "react-router";
import {MessageContext} from "../MessageContext";
import SecondButton from '../atom/Buttons/SecondButton';
import ThaadButton from '../atom/Buttons/ThaadButton';
import UserDeleteDialog from '../comm/User/UserDeleteDialog';
import BackdropLodding from '../comm/BackDrop';
import {ErrorContext} from '../ErrorContext';
import {Delete} from '@mui/icons-material';

interface IBookDetailParam {
  id: string
}


const schema: yup.SchemaOf<Omit<TStudent, 'id' | 'note'>> = yup.object({
  name: yup.string().required('利用者名を入力してください'),
  kana: yup.string().required('利用者名 かなを入力してください'),
  email: yup.string().required('emailを入力してください'),
  birthday: yup.string().required('生年月日を入力してください'),
  school_name: yup.string().required('学校名を入力してください'),
  school_year: yup.number().required('学年を入力してください'),//"数値を入力してください"
  sex: yup.string().required('性別を入力してください'),
  mobile_phone: yup.string().required('本人の連絡先を入力してください'),
  emegency_contact: yup.string().required('緊急連絡先を入力してください'),
  parent_name: yup.string().required('保護者名を入力してください'),
  parent_contact: yup.string().required('保護者の連絡先を入力してください'),
  service_no: yup.string().required('受給者証番号を入力してください'),
  service_provision: yup.string().required('支給量を入力してください'),
  password: yup.string(),
  retype_password: yup.string(),

})

const UserDetail: FC = () => {
  const {id} = useParams() as IBookDetailParam
  const [openDeleteDialog,setOpenDeleteDialog] = useState<boolean>(false)
  const [isChangePass,setIsChangePass] = useState<boolean>(false)
  const {data, loading, error,handleDeleteRequest, handlePostRequest,handlePostAddRequest,errorState,setErrorStateState} = useFetchUserDetail(Number(id))
  const messageContext = useContext(MessageContext)
  const {control,setValue, handleSubmit,formState: {errors}} = useForm<TStudent>(
      {resolver: yupResolver(schema),}
  );
  const navigate = useNavigate();
  const errorContext = useContext(ErrorContext)
  const [userMessage, setUserMessage] = useState<string|null>(null)

  const days = Array.from(new Array(20)).map((v, i) => i + 1)
  const creatrPassword = Math.random().toString(36).slice(-8);
  const handleDelete = ()=>{
    handleDeleteRequest(id,() =>{
      messageContext.handleSetMessage('利用者情報を削除しました。')
      setTimeout(()=>{navigate('/users')},1000)
    })

  }
  useEffect(() => {
    if(data.user){
      if(data.user.id !== Number(id)) navigate(`/user/${data.user.id}`)
    }
  },[data])
  useEffect(() => {
    if (loading) messageContext.handleSetMessage('')

  }, [loading])

  useEffect(() => {
    if (!loading && data.message == 'user update successfully') messageContext.handleSetMessage('利用者情報を更新しました。')

  }, [loading])

  useEffect(() => {
    console.log(errorState)
    errorContext.setContextError(
      errorState.status,
      errorState.userMessage ? errorState.userMessage : '',
      errorState.serverErrror ? errorState.serverErrror : undefined)
    setUserMessage(prevState => errorState.userMessage)
  },[errorState,setErrorStateState])


  if (loading) return <><BackdropLodding showBackdrop={loading}/></>

  if (error?.code === '500') return <> <p>Error!</p></>


  const onSubmit: SubmitHandler<TStudent> = formData => {
    if(id === '0'){
      handlePostAddRequest(formData)
    }else {
      if(!isChangePass) delete formData.password
      handlePostRequest(formData)
    }
  };

  const handleChagePasswod =(value: string) => {
    setIsChangePass(prevState => !prevState)
    setValue('password',creatrPassword)
    setValue('retype_password',creatrPassword)
  }

  return (
      <>
        <Box>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography
                  variant={"h5"}>利用者{id === '0' ? '登録' : '編集'}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end">
              <p style={{textAlign: "right"}}><SecondButton onClick={() => navigate('/users/')} label="戻る" /></p>
              {Number(id) > 0 && <p style={{textAlign: "right"}}><ThaadButton onClick={() => setOpenDeleteDialog(prevState => true)} label="削除" /></p>}
            </Grid>



          </Grid>
        </Box>
        <UserDeleteDialog open={openDeleteDialog} onClose={setOpenDeleteDialog} onDelete={handleDelete} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Grid container spacing={4} rowSpacing={4}>
              <Grid item xs={12} sm={6}>
                <Controller name="name" control={control} defaultValue={data.user?.name}
                            render={({field}) => <TextField {...field} error={"name" in errors} fullWidth
                                                            placeholder="利用者名"
                                                            label="利用者名"
                                                            autoFocus
                                                            helperText={errors.name?.message}/>}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller name="sex" control={control} defaultValue={data.user?.sex}
                            render={({field}) => <TextField {...field} error={"sex" in errors} fullWidth
                                                            placeholder="性別"
                                                            label="性別"
                                                            helperText={errors.sex?.message} select>
                              <MenuItem value={0}>男</MenuItem>
                              <MenuItem value={1}>女</MenuItem>
                            </TextField>}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller name="kana" control={control} defaultValue={data.user?.kana}
                            render={({field}) => <TextField {...field} error={"kana" in errors} fullWidth
                                                            placeholder="利用者名 かな"
                                                            label="利用者名　かな"
                                                            helperText={errors.kana?.message}/>}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={4}>
                <Controller name="birthday" control={control} defaultValue={data.user?.birthday}
                            render={({field}) => <TextField {...field} error={"b" in errors} fullWidth
                                                            type="date"
                                                            //placeholder="生年月日"
                                                            label="生年月日"
                                                            helperText={errors.birthday?.message}/>}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={4}>
                <Controller name="email" control={control} defaultValue={data.user?.email}
                            render={({field}) => <TextField {...field} error={"email" in errors} fullWidth
                                                            placeholder="email"
                                                            label="email"
                                                            helperText={errors.email?.message}/>}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={4}>
                <Controller name="mobile_phone" control={control} defaultValue={data.user?.mobile_phone}
                            render={({field}) => <TextField {...field} error={"mobile_phone" in errors} fullWidth
                                                            placeholder="本人の連絡先"
                                                            label="本人の連絡先"
                                                            helperText={errors.mobile_phone?.message}/>}
                />
              </Grid>

              <Grid item xs={12} md={6} sm={4}>
                <Controller name="emegency_contact" control={control} defaultValue={data.user?.emegency_contact}
                            render={({field}) => <TextField {...field} error={"emegency_contact" in errors} fullWidth
                                                            placeholder="緊急連絡先"
                                                            label="緊急連絡先"
                                                            helperText={errors.emegency_contact?.message}/>}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Controller name="school_name" control={control} defaultValue={data.user?.school_name}
                            render={({field}) => <TextField {...field} error={"school_name" in errors} fullWidth
                                                            placeholder="学校名"
                                                            label="学校名"
                                                            helperText={errors.school_name?.message}/>}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller name="school_year" control={control} defaultValue={data.user?.school_year}
                            render={({field}) => <TextField {...field} error={"school_year" in errors} fullWidth
                                                            placeholder="学年"
                                                            label="学年"
                                                            helperText={errors.school_year?.message}/>}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"h5"}>保護者</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Controller name="parent_name" control={control} defaultValue={data.user?.parent_name}
                            render={({field}) => <TextField {...field} error={"parent_name" in errors} fullWidth
                                                            placeholder="保護者名"
                                                            label="保護者名"
                                                            helperText={errors.parent_name?.message}/>}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller name="parent_contact" control={control} defaultValue={data.user?.parent_contact}
                            render={({field}) => <TextField {...field} error={"parent_contact" in errors} fullWidth
                                                            placeholder="保護者名の連絡先"
                                                            label="保護者名の連絡先"
                                                            helperText={errors.parent_contact?.message}/>}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                    variant={"h5"}>受給者証</Typography>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller name="service_no" control={control} defaultValue={data.user?.service_no}
                            render={({field}) => <TextField {...field} error={"service_no" in errors} fullWidth
                                                            placeholder="受給者証番号"
                                                            label="受給者証番号"
                                                            helperText={errors.service_no?.message}/>}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller name="service_provision" control={control} defaultValue={data.user?.service_provision}
                            render={({field}) => <TextField {...field} error={"service_provision" in errors} fullWidth
                                                            placeholder="支給量"
                                                            label="支給量"
                                                            helperText={errors.service_provision?.message} select>
                              <MenuItem value={0}>原則の日</MenuItem>
                              {days.map(i => (
                                  <MenuItem value={i}>{i}日</MenuItem>
                              ))}
                            </TextField>}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={"h5"}>パスワード {id !== '0' && <FormControlLabel control={<Switch onChange={event => handleChagePasswod(event.target.value)}></Switch>} label="パスワードを変更する"/>} </Typography>
              </Grid>
              {isChangePass && (<>
              <Grid item sm={6} xs={12}>
                <Controller name="password" control={control} defaultValue={creatrPassword}
                            render={({field}) => <TextField {...field} error={"parent_name" in errors} fullWidth
                                                            placeholder="パスワード"
                                                            label="パスワード"
                                                            helperText={errors.parent_name?.message}/>}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller name="retype_password" control={control} defaultValue={creatrPassword}
                            render={({field}) => <TextField {...field} error={"parent_contact" in errors} fullWidth
                                                            placeholder="パスワード(確認)"
                                                            label="パスワード(確認)"
                                                            helperText={errors.parent_contact?.message}/>}
                />
              </Grid>
                </>)}
              <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>

                <DefaultButton type="submit" label={id === '0' ? '登録' : '保存'}/>
              </Grid>
            </Grid>

          </Box>
        </form>

      </>
  );
};

export default UserDetail