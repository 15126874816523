import {useState} from 'react';
import http from '../Stores/http';
import {AxiosError} from 'axios';
import {ICurriculumDetailResponse, ICurriculum, SystemMessage} from '../Types/Response'

interface IResponse {
  curriculums: Array<ICurriculum>
  error: AxiosError | null;
  loading: boolean;
  systemMessage:SystemMessage|undefined
}

interface ICurritulums {
  lectures: ICurriculum[]
}

interface IPostCurriculum {
  lecture_date: string,
  lecture_id: number,
  title: string,
  url: string,
}


interface IPostCurritulums {
  curriculums: IPostCurriculum[]
}


const useFetchBooks = (year: string, month: string) => {
  const [response, setResonse] = useState<IResponse>({
    curriculums: [],
    error: null,
    loading: false,
    systemMessage: undefined
  })
  const [calendarDate, setCalendarDate] = useState<Date>(new Date(`${year}/${month}/01`))


  const handleCalendarUP = () => {
    let carrentDate = calendarDate
    carrentDate.setMonth(carrentDate.getMonth() + 1)
    setCalendarDate(carrentDate)
    fetchRequest()
  }
  const handleCalendarDown = () => {
    let carrentDate = calendarDate
    carrentDate.setMonth(carrentDate.getMonth() - 1)
    setCalendarDate(carrentDate)
    fetchRequest()
  }

  const handlePostRequest = ()=>{
    if(response.curriculums !== undefined){
    setResonse(prevState => ({...prevState, loading: true}))
    let post:IPostCurritulums={curriculums:[]}

      // eslint-disable-next-line array-callback-return
    response.curriculums?.map(value => {
      post.curriculums.push({...value,lecture_date:formatDate(value.lecture_date)})
    })
    // http.post<ICurritulums<string> | null>('curriculums/update',{curriculums:[{id:1,lecture_date:'2021-12-29',lecture_id:0}]})
    http.post<ICurritulums|null>('curriculums/update',post)
      .then((response) => {
        fetchRequest()
      })
      .catch((error: AxiosError) => {
        console.log(error)
        setResonse((prevState) => ({...prevState,error:error,loading:false}))

      })

  }
  }
  const formatDate = (current_datetime:Date)=>{
    let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate();
    return formatted_date;
  }

  const fetchRequest = () => {
    setResonse(prevState => ({...prevState, loading: true}))
    let month = calendarDate.getMonth() + 1
    // let month = 13
    http.get<ICurritulums | null>('curriculums/books/' + calendarDate.getFullYear() + '/' + month)
        .then((response) => {
          console.log(response.data)
          let currilumus: Array<ICurriculum> = []
          response.data?.lectures?.map(value => {
            currilumus.push({...value, lecture_date: new Date(value.lecture_date)})
          })
          setResonse(prevState => ({
            ...prevState,
            curriculums: currilumus,
            error: null,
            loading: false
          }))
        })
        .catch((error: AxiosError) => {
          console.log(error)
          setResonse(prevState => (
            {
              ...prevState,
              error:error,
              loading:false,
              systemMessage:{text:"サーバーでErrorが発生いたしました",isDelete:false}
            }))

        })
  }
  return {
    ...response,
    fetchRequest,
    calendarDate,
    setCalendarDate,
    handlePostRequest,
    handleCalendarUP,
    handleCalendarDown
  }
};
export default useFetchBooks;