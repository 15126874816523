import {ICurriculum} from '../Types/Response';
import useCurriculum from './useCurriculum';

export type TWeeks = Array<Array<ICurriculum>>

const useCalendar = ()=>{
  const {newCurriculum} = useCurriculum()

  const createCurriculumCalendar =(curriculums:ICurriculum[]):TWeeks =>{
    let weeks: TWeeks = []

      let current = curriculums.concat()
    const sDay = current[0].lecture_date.getDay()
    let startDay = new Date(current[0].lecture_date)
    for (let i = 0; i <= sDay - 1; i++) {
      startDay.setDate(startDay.getDate() - 1)
      current.unshift(newCurriculum(new Date(startDay)))
    }
    const eDay = curriculums[curriculums.length - 1].lecture_date.getDay() + 1
    let endDay = new Date(current[current.length - 1].lecture_date)
    for (let i = eDay; i < 7; i++) {
      endDay.setDate(endDay.getDate() + 1)
      current.push(newCurriculum(new Date(endDay)))
    }
    console.log(current)
    current.map((value, index) => {
      if (index == 0 || index % 7 == 0) {
        weeks.push([])
      }
      weeks[weeks.length - 1].push(value)
    })
    return  weeks
  }

  return {createCurriculumCalendar}
}
export default useCalendar