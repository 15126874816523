import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Dispatch, FC, SetStateAction} from 'react';
import ThaadButton from '../../atom/Buttons/ThaadButton';
type Prop ={
  open:boolean,
  onClose:Dispatch<SetStateAction<boolean>>,
  onDelete:()=>void
}
const UserDeleteDialog:FC<Prop> = ({open,onClose,onDelete}) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(prevState => false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          利用者情報を削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            利用者情報を削除しますか？<br />一度削除した場合、元に戻りません。ご注意してください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(prevState => false)} autoFocus>キャンセル</Button>
          <ThaadButton onClick={onDelete} label="実行"/>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default UserDeleteDialog