import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute'
import Curriculums from './Components/Pages/Curriculums';
import Home from './Components/Pages/Home'
import Users from './Components/Pages/Users';
import Books from './Components/Pages/Books';
import Error from './Components/Pages/Error';
import Login from './Components/Pages/Login';
import AuthUserProvider from './Components/AuthUserProvider';
import Logout from './Components/Pages/Logout';
import UserDetail from './Components/Pages/UserDetail';
import CurriculumDetail from './Components/Pages/CurriculumDetail';
import {ErrorProvider} from './Components/ErrorContext';
import GenericTemplate from './Components/Templates/GenericTemplate';
import {ThemeProvider} from '@mui/material/styles';
import Theme from './Components/ThemeProvider';
import {MessageProvider} from './Components/MessageContext';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://78ee18e07f1d40ceb959b30933635c43@o1096680.ingest.sentry.io/6117430",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <div className="App">
      <AuthUserProvider>
          <ThemeProvider theme={Theme}>
              <MessageProvider>
              <BrowserRouter>
                <Routes>
                  <Route index element={<PrivateRoute><Users/></PrivateRoute>}/>
                  <Route path="curriculums" element={<PrivateRoute><Curriculums/></PrivateRoute>}/>
                  <Route path="users" element={<PrivateRoute><Users/></PrivateRoute>}/>
                  <Route path="user/:id" element={<PrivateRoute><UserDetail/></PrivateRoute>}/>
                  <Route path="books/:year/:month" element={<PrivateRoute><Books/></PrivateRoute>}/>
                  <Route path="book/:id" element={<PrivateRoute><CurriculumDetail/></PrivateRoute>}/>
                  <Route path="error" element={<GenericTemplate><Error code={500}/></GenericTemplate>}/>
                  <Route path="login" element={<GenericTemplate><Login/></GenericTemplate>}/>
                  <Route path={'logout'} element={<GenericTemplate><Logout/></GenericTemplate>}/>
                  <Route path="*" element={<Error code={404}/>}/>
                </Routes>
              </BrowserRouter>
              </MessageProvider>
          </ThemeProvider>
      </AuthUserProvider>
    </div>
  );
}

export default App;
