import React, {FC} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
type Prop={
  showBackdrop:boolean
}
const BackdropLodding:FC<Prop> = ({showBackdrop}) => {
  return (
    <Backdrop open={showBackdrop} style={{zIndex:1}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLodding;