import React, { createContext, useContext, useState } from "react"
import AuthUser from "../Types/Auth"
import axios, {AxiosError} from 'axios';
import Config from "../Types/config";
import {ErrorContextInterface} from '../Types/Error';
import * as Sentry from "@sentry/react";

type OperationType = {
  Login: (email: string,password:string,callBack:Function) => void
  logout: (callback:Function) => void
  getErrorFunction:() => ErrorContextInterface
  user: () => void
}

// interface IPostRequest{
//   email: string
//   password: string
// }
//
interface PostResponse {
  id: number
  access_token: string
}

// interface IErrorResponse {
//   error: string
// }

const AuthUserContext = createContext<AuthUser | null>(null)
export const AuthOperationContext = createContext<OperationType>({
  Login: (_) => console.error("Providerが設定されていません"),
  logout: ()  => console.error("Providerが設定されていません"),
  user: () => console.error("Providerが設定されていません"),
  getErrorFunction:()=>{
    return {hasError:false,setContextErrorDone:()=>{},setContextError:()=>{} ,userMessage:null,serverErrror:null,status:200}
  }

})

const AuthUserProvider: React.FC = ({ children }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [errorState, setErrorStateState] = useState<ErrorContextInterface>(
    {setContextErrorDone:()=>{},setContextError:()=>{} ,userMessage:null,serverErrror:null,status:200}
  )
  const getErrorFunction =() =>{
    return errorState
  }
  const handleError = (error: AxiosError) => {
    Sentry.captureException(error);
    if (error.response) {
      if(error.response.status === 401 || error.response.status === 422) {
        setErrorStateState(prevState => ({...prevState,userMessage:"emailか、パスワードが違います。",status:401}))
      }else{
        setErrorStateState(prevState => ({...prevState,userMessage:"サーバに不具合があります。",status:500,serverErrror:error.response?.data}))
      }

    } else {
      console.log(error.message);
    }
  };
  const Login = async (email: string,password:string,callback:Function) => {
    console.log("sumit post Login")
    axios.post<PostResponse>(Config.apiIrl + "login",{
      email:email,
      password:password
    },{
      headers:{
        "Access-Control-Allow-Origin": "http://localhost:3002",
        "ontent-type":"application/json"
      }
    }
    ).then((res)=>{
      localStorage.setItem('user',res.data.access_token)
      setAuthUser({tokken:res.data.access_token})
      callback()
    }).catch(reason => {

      handleError(reason)
    })

     }
  const logout = async (callback:Function) => {
    // await login() //ログアウト処理
    setAuthUser(null)
    callback()
  }
  const user = () => {
    // await login() //ログアウト処理
    return authUser
  }

  return (
    <AuthOperationContext.Provider value={{Login, logout,user,getErrorFunction}}>
      <AuthUserContext.Provider value={authUser}>
        { children }
      </AuthUserContext.Provider>
    </AuthOperationContext.Provider>
  )
}

export const useAuthUser = () => useContext(AuthUserContext)
export const useLogout = () => useContext(AuthOperationContext).logout
export const useGetTokken = () => useContext(AuthOperationContext).user
export const useLogin = () => useContext(AuthOperationContext).Login
export const useLoginError = () => useContext(AuthOperationContext).getErrorFunction
//
// export const useLogin = () =>{
//
// }
export default AuthUserProvider
