import React, {ChangeEvent, Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import {ICurriculum} from '../../Types/Response';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField} from '@mui/material';
import SecondButton from '../atom/Buttons/SecondButton';
import DefaultButton from '../atom/Buttons/DefaultButton';
import useFetchcurriculums from "../../Hooks/useFetchCurriculums";
import ThaadButton from "../atom/Buttons/ThaadButton";
import {MessageContext} from "../MessageContext";

type Props={
  isOpen:boolean
  curriculum:ICurriculum
  onClose:Dispatch<SetStateAction<boolean>>
  handleCheangeCurritulums: (cCurriculum: ICurriculum) => void

}
export type TCurriculumFile ={
  fileName:string
  url:string
}
const SpecialCurritulumEdit:FC<Props> = ({isOpen,curriculum,onClose,handleCheangeCurritulums})=>{
  const [editTiltle,setEditTitle] = useState<string>(curriculum.title)
  const [editFile,setEditFile] = useState<TCurriculumFile>({fileName:curriculum.file_name,url:curriculum.url})
  const {handleFileIploadRequest,uploadDelete,systemMessage} = useFetchcurriculums()
  const messageContext = useContext(MessageContext)

  const handleCheangeSpeacilCurriculum = () => {
    handleCheangeCurritulums({...curriculum,title:editTiltle,file_name:editFile.fileName,url:editFile.url})
    onClose(prevState => false)
  }
  //todo file Upload
  //todo layout

  const handleCheangeTitle = (title:string)=> {
    setEditTitle(prevState => title)

  }
  const handleCheangeFile = ()=> {
    uploadDelete(Number(curriculum.id))
    setEditFile({fileName:'',url:''})

  }
  const handleFileuoload = (fileList:FileList|null) => {
    if(fileList) {
      const files:FileList = fileList
      handleFileIploadRequest(Number(curriculum.id), files,setEditFile)
    }

  }
  useEffect(() => {
    messageContext.handleSetMessage(systemMessage?.text ? systemMessage.text : '')
  }, [systemMessage])

  return (
   <>
     <Dialog open={isOpen} onClose={()=> onClose(prevState => false)}>
       <DialogTitle>特別授業編集</DialogTitle>
       <DialogContent>
         <Stack direction={{sm:"column",xs:'row'}} spacing={{md:2,xs:4}}>
         <TextField
           margin="dense"
           id="tel"
           label="授業タイトル"
           type="text"
           fullWidth
           // disabled={!isTitle}
           variant="standard"
           value={editTiltle}
           onChange={(event) => {handleCheangeTitle(event.target.value)}}
         />
           {editFile.url?.length > 0 ? <>
             <a href={editFile.url} target="_blank">{editFile.fileName}</a>
             <ThaadButton label="削除" onClick={event => handleCheangeFile() } /></>: <>
         <Button variant="contained" component="label">
           カリキュラム　アップロード
           <input
             type="file"
             onChange={(e) =>handleFileuoload(e.target.files)}
             hidden
           />
         </Button>
           </>}
         </Stack>
       </DialogContent>
       <DialogActions>
         <SecondButton onClick={()=> onClose(prevState => false)} label="キャンセル"/>
         <DefaultButton onClick={handleCheangeSpeacilCurriculum} label="保存" />
       </DialogActions>
     </Dialog>

       </>
 )
}
export default SpecialCurritulumEdit