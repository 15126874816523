import React, {createContext, useContext, useEffect, useState} from 'react';
import {ErrorContextInterface, LaravelError} from '../Types/Error';
import {useNavigate} from 'react-router-dom';

export const ErrorContext = createContext<ErrorContextInterface>({
  userMessage: null,
  serverErrror: null,
  status:200,
  setContextError: (status, userMessage, error) => {
  },
  setContextErrorDone: () => {
  },
});


export const ErrorProvider: React.FC<object> = ({children}) => {
  const [userMessage, setUserMessage] = useState<string | null>(null);
  const [serverErrror, setServerErrror] = useState<LaravelError | null>(null);
  const [status, setStatus] = useState<number>(0);
  const navigate = useNavigate()

  const setContextError = (status:number=500, userMessage: string, error?: LaravelError) => {
    setStatus(status)
    setUserMessage(userMessage);
   if(typeof error === 'object')  setServerErrror(error);
  };

  const setContextErrorDone = () => {
    setUserMessage(null);
    setServerErrror(null);
    setStatus(0)
  };
  useEffect(() => {
    if(status === 500){
      setContextErrorDone()
      navigate('/error')
    }
  },[setStatus,status])

  return (
    <ErrorContext.Provider
      value={{status,userMessage,serverErrror, setContextError,setContextErrorDone}}
    >
      {children}
    </ErrorContext.Provider>
  );
};
//
export const useError = () => {
  const context = useContext(ErrorContext)

  return context
}
