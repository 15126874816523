import {FC} from "react";
import Box from "../../atom/Box";
import EditIcon from '@mui/icons-material/Edit';
import {User} from "../../../Types/User";
import {Link} from "react-router-dom";
import {buttonProp} from "../../Organisms/UserSearch";
import {Avatar, Button, Stack} from "@mui/material";
import {blue, red} from "@mui/material/colors";
import SecondButton from "../../atom/Buttons/SecondButton";

type Props = {
  user: User
  button?: buttonProp | undefined

}

const Info: FC<Props> = ({user, button}) => {
  return (
      <>
        <Box>
          <p><Avatar sx={{
            margin: 'auto',
            bgcolor: user.sex == '1' ? red['300'] : blue['300']
          }}>{user.name.substring(0, 1)}</Avatar></p>
          <p style={{width: 120}}>  {user.name}</p>
          <p style={{width: 120}}>  {user.tel}</p>
          {button ?
              <p><SecondButton onClick={() => button.func(user.id)} label={button.label}/></p>
              :
              <Stack justifyContent="right" direction="row"><Link to={`/user/${user.id}`}><Avatar
                  sx={{width: "1.5em", height: "1.5em"}}><EditIcon/></Avatar></Link></Stack>}
        </Box>
      </>

  )
}
export default Info