import {FC, useContext, useEffect, useState} from 'react';
import {useLogin,} from '../AuthUserProvider';
import {useLocation, useNavigate} from 'react-router-dom';
import {Alert, Button, Paper, Stack, TextField} from '@mui/material';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/system';
import Config from '../../Types/config';
import {AuthOperationContext} from '../AuthUserProvider'
import BackdropLodding from '../comm/BackDrop';
import {ErrorContext} from '../ErrorContext'

const LoginBox = styled(Paper)(({theme}) => ({
  textAlign: 'center',
  minWidth: '80%',
  padding:50
}));

const Login: FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsloading] = useState<boolean>(false)
  const login = useLogin()
  const navigate = useNavigate()
  const location = useLocation()
  const {from} = location.state || {from: {pathname: '/login'}}
  const authOperationContext = useContext(AuthOperationContext)
  const errorContext = useContext(ErrorContext)
  const [userMessage, setUserMessage] = useState<string|null>(null)


  const handleLogin = () => {
    setIsloading(prevState => true)
    login(email, password, () => {
      navigate(from)
    })
  }
  useEffect(() => {
    setIsloading(prevState => false)
    const errors = authOperationContext.getErrorFunction()
    console.log(errors)
    errorContext.setContextError(
      errors.status,
      errors.userMessage ? errors.userMessage : '',
      errors.serverErrror ? errors.serverErrror : undefined)
    // if(errors.status === 500) navigate('/error')

    setUserMessage(prevState => errors.userMessage)
  },[authOperationContext.getErrorFunction])

  if(isLoading) return <BackdropLodding showBackdrop={isLoading}/>

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{minHeight: '90vh'}}
    >

      <LoginBox>
        <Stack spacing={4}>
          <h1><img src="./passo-logo.fw.png" height="50" alt=""/></h1>
          {Config.applicationTitle}ログイン
          {userMessage && (
            <Alert severity="warning">{userMessage}
            </Alert>
          )}

          <TextField required label="メールアドレス" type="email" value={email}
                     onChange={event => setEmail(prevState => event.target.value)}/>
          <TextField required label="パスワード" type="password" value={password}
                     onChange={event => setPassword(prevState => event.target.value)}/>
          <Button onClick={handleLogin} autoFocus>ログイン</Button>
        </Stack>
      </LoginBox>
    </Grid>
  )

}
export default Login