import {FC, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuthUser} from './AuthUserProvider';
import Header from './Organisms/Header';
import {DropMemu} from './Molecules/DropMenu';
import {Box} from '@mui/material';
import Footer from './Organisms/Footer';
import {ErrorProvider} from './ErrorContext';

export interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({children}) => {
  const authUser = useAuthUser()
  const isAuthenticated = authUser != null
  const location = useLocation()
  const [dropMenuOpen, setDropMenurOpen] = useState(false);
  const toggleDropMenu = () => setDropMenurOpen(!dropMenuOpen);


  return isAuthenticated ?
    <>
      <ErrorProvider>
      <Header onOpen={toggleDropMenu}/>
      <DropMemu open={dropMenuOpen} onClose={toggleDropMenu}/>
      <Box sx={{m: 2}}>
        {children}
      </Box>
      <Footer/>
      </ErrorProvider>
    </>
    :
    (<Navigate to="/login" state={{from: location}}/>)

}

export default PrivateRoute