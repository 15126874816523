import {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import useFetchcurriculums from '../../Hooks/useFetchCurriculums';
import {MessageContext} from '../MessageContext'
import {
  Box,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import DefaultButton from '../atom/Buttons/DefaultButton';
import {ICurriculum} from '../../Types/Response';
import useCurriculum from '../../Hooks/useCurriculum';
import CurriculumCell from '../comm/CurritulumCell';
import useCalendar, {TWeeks} from '../../Hooks/useCalendar';
import BackdropLodding from '../comm/BackDrop';

const Curriculums: FC = () => {
  const {
    loading,
    fetchRequest,
    systemMessage,
    curriculums,
    setResonse,
    calendarDate,
    lecture_master,
    handlePostRequest,
    handleCalendarDown,
    handleCalendarUP
  } = useFetchcurriculums()
  const [weeks, setWeeks] = useState<TWeeks>([[]])
  const messageContext = useContext(MessageContext)
  const {createCurriculumCalendar} = useCalendar()

  const handleCheangeCurritulums = (curriculum: ICurriculum) => {
    let newCurriculums = curriculums.concat()
    newCurriculums[curriculum.lecture_date.getDate() - 1] = curriculum
    setResonse(prevState => ({...prevState, curriculums: newCurriculums}))
  }

  // const callBackCheangeCurritulums = useCallback(handleCheangeCurritulums,[setResonse,curriculums])

  useEffect(() => {
    fetchRequest()
  }, [])

  useEffect(() => {
    if (curriculums.length > 0) {
      setWeeks(prevState => createCurriculumCalendar(curriculums))
    }
  }, [curriculums])

  useEffect(() => {
    console.log(systemMessage)
    messageContext.handleSetMessage( 'カリキュラムを読み込みました。')
  }, [systemMessage])


  useEffect(() => {
    if (loading) messageContext.handleSetMessage('')

  }, [loading])

  if (loading) return <><BackdropLodding showBackdrop={loading}/></>


  return (
      <>
        <Box sx={{my: 3}}>
          <Grid container alignItems="center">
            <Grid item sm={8}>
              <Typography variant={'h5'}>
                {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 カリキュラム
              </Typography>
            </Grid>
            <Grid item sm={4} justifyContent="center">
              <Stack justifyContent="flex-end" direction={{md: "row", xs: 'row'}} alignItems="right" spacing={3}>

                <DefaultButton onClick={handleCalendarDown} label="前月"/>

                <DefaultButton onClick={handleCalendarUP} label="翌月"/>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <table style={{width: '100%', border: '1px soild #eee', borderSpacing: '0'}}>
          {weeks.map((week, key) =>
              <tr>
                {week.map((day, index) =>
                    <CurriculumCell
                        curriculum={day}
                        handleCheangeCurritulums={handleCheangeCurritulums}
                        lecutureMaster={lecture_master}
                        currentMonth={calendarDate.getMonth()}/>
                )}
              </tr>
          )}

        </table>

        <Grid container alignItems="center">
          <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>

            <DefaultButton onClick={handlePostRequest} label="保存"/>
          </Grid>
        </Grid>
      </>
  )

}

export default Curriculums