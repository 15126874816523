import {useEffect, useState} from 'react';
import http from '../Stores/http';
import {User} from '../Types/User';
import {AxiosError} from 'axios';

interface IResponse {
  data: Array<User>|null;
}
const useFetchUsers = () => {
  const [response, setResonse] = useState<IResponse>({data: null})
  const [error, setError] = useState<AxiosError | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    fetchRequest()
  }, [])

  const fetchRequest = () => {
    setLoading(true)
    http.post<Array<User> | null>('users')
        .then((response) => {
          setUsers(response.data ? response.data : [])
          setLoading(false)
        })
        .catch((error: AxiosError) => {
          console.log(error)

        })
  }
  return {users, setUsers, error, loading}
};
export default useFetchUsers;