import {styled} from "@mui/system";
import {Paper} from "@mui/material";
import {FC, PropsWithChildren, ReactNode} from "react";


const SBox = styled(Paper)(({theme}) => ({
  textAlign: 'center',
  padding: 20,
}));
type Prop = {}
const Box: FC<Prop> = ({children}) => {
  return (
      <SBox>{children}</SBox>
  )
}
export default Box