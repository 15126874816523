import {Grid, Paper, Stack, Typography} from '@mui/material';
import {FC, useContext} from 'react';
import {styled} from '@mui/system';
import {ErrorContext} from '../ErrorContext';
type Porps ={
  code:number
}

const SBox = styled(Paper)(({theme}) => ({
  textAlign: 'center',
  minWidth: '80%',
  padding:50
}));


const Error:FC<Porps> =({code}) =>{
  const errorContext = useContext(ErrorContext)

  return(
      <SBox><Typography variant={"h5"}>申し訳ありません。サーバに不具合が発生しました。</Typography>
          <Typography ><img src="./man-sorry.png" width={200}/></Typography>
        <Typography >仕事を止めてしまい、本当にごめんなさい。管理者に連絡をお願いします。</Typography>
      </SBox>
  )

}
export default Error