import {useEffect, useState} from 'react';
import http from '../Stores/http';
import {TStudent, User} from '../Types/User';
import {AxiosError} from 'axios';
import {ErrorContextInterface} from '../Types/Error';
import * as Sentry from '@sentry/react';

interface IResponse {
  data: { message: string | null, user: TStudent | null };
  error: AxiosError | null;
  loading: boolean;
}

interface IErrorMessage {
  hasErr: boolean,
  text: string,

}

const useFetchUserDetail = (id: number) => {
  const [response, setResonse] = useState<IResponse>({data: {message: '', user: null}, error: null, loading: false})
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>({hasErr: false, text: ''})
  const [errorState, setErrorStateState] = useState<ErrorContextInterface>(
    {
      setContextErrorDone: () => {
      }, setContextError: () => {
      }, userMessage: null, serverErrror: null, status: 200
    }
  )

  useEffect(() => {
    if (id > 0) fetchRequest(id)
  }, [id])

  const handleError = (error: AxiosError, userMessage: string) => {
    Sentry.captureException(error);
    console.log(error)
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 422) {
        setErrorStateState(prevState => ({...prevState, userMessage: userMessage, status: 401}))
      } else {
        setErrorStateState(prevState => ({
          ...prevState,
          userMessage: 'サーバに不具合があります。',
          status: 500,
          serverErrror: error.response?.data
        }))
      }

    } else {
      console.log(error.message);
    }
  };

  const fetchRequest = (id: number) => {
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<{ user: TStudent, message: string }>('users/store', {id: id})
      .then((response) => {
        setResonse({data: {user: response.data.user, message: response.data.message}, error: null, loading: false})
      })
      .catch((error: AxiosError) => {
        console.log(error)
        setResonse({'data': {user: null, message: ''}, error: error, loading: false})

      })
  }

  const handleErrorClear = () => {
    setErrorMessage({hasErr: false, text: ''})
  }
  const handlePostRequest = (postData: Omit<TStudent, 'id'>) => {
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<{ user: TStudent, message: string }>('users/update/' + response.data.user?.id, postData)
      .then((response) => {
        setErrorMessage({hasErr: true, text: response.data.message})
        setResonse({data: {user: response.data.user, message: response.data.message}, error: null, loading: false})
      })
      .catch((error: AxiosError) => {
        handleError(error, '')
      })
  }
  const handlePostAddRequest = (postData: Omit<TStudent, 'id'>) => {
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<{ user: TStudent, message: string }>('users/create/', postData)
      .then((response) => {
        setErrorMessage({hasErr: true, text: response.data.message})
        setResonse({data: {user: response.data.user, message: response.data.message}, error: null, loading: false})
      })
      .catch((error: AxiosError) => {
        handleError(error, '登録できませんでした。')
      })
  }
  const handleDeleteRequest = (id: string, callback: () => void) => {
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<{ message: string }>('users/destroy/' + response.data.user?.id)
      .then((response) => {
        callback()
      })
      .catch((error: AxiosError) => {
        handleError(error, '利用者を削除できませんでした。')
      })

  }
  const handleChange = (key: 'id' | 'name' | 'kana' | 'sex' | 'tel' | 'biryday' | 'goAt', value: string) => {
    let data = response.data

    if (data.user) data.user = {...data.user, [key]: value};
    setResonse(prevState => ({...prevState, data: data}))
    handleErrorClear()
  }
  return {
    ...response,
    setResonse,
    handleDeleteRequest,
    handlePostAddRequest,
    handleChange,
    handlePostRequest,
    errorState,
    setErrorStateState
  }
};
export default useFetchUserDetail;