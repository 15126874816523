import React, {FC} from 'react';
import useFetchUsers from '../../Hooks/useFetchUser'
import {Link} from 'react-router-dom';
import Info from "../comm/User/Info";
import {Box, Button, Fab, Grid, Paper, Typography} from '@mui/material';
import {styled} from "@mui/styles";
import UserSearch from "../Organisms/UserSearch";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router';
import BackdropLodding from '../comm/BackDrop';
const Item = styled(Paper)(({theme}) => ({
  textAlign: 'center',
}));

const Users: FC = () => {
  const {users, setUsers, loading, error} = useFetchUsers()
  const t = localStorage.getItem('user')
  const navigate = useNavigate();
  const handleNewUser =()=>{
    navigate('/user/0')
  }

  if (loading) return <><BackdropLodding showBackdrop={loading}/></>
  if (error) return <>{t ? 1 : 0} <p>Error!</p></>

  return (

      <>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography
                variant={"h5"}>利用者一覧</Typography>
          </Grid>
          <Grid item xs={2}>

          </Grid>

        </Grid>
        <UserSearch users={users ? users : []} usersUpdate={setUsers}/>
        <Grid container alignItems="center">
          <Grid sx={{position:'fixed',bottom:60,right:20,}}>

          <Fab aria-label="add">
          <AddIcon onClick={handleNewUser} />
        </Fab>
          </Grid></Grid>
      </>
  )


}
export default Users