import {FC} from 'react';
import {useLogout} from '../AuthUserProvider';
import {useNavigate} from 'react-router-dom';

const Logout:FC =() =>{
  const logout = useLogout()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout(() =>{navigate('/login')})
  }
  return(
    <>
      <h1>Logout</h1>
      <p><button onClick={handleLogout}>ログアウト</button></p>
    </>
  )

}
export default Logout