import {ICurriculum} from '../Types/Response';

const useCurriculum = ()=> {

  const newCurriculum = (targetDate:Date) =>{
    let curriculum:ICurriculum = emptyCurriculum()
    return {...curriculum,lecture_date:targetDate}
  }
  const emptyCurriculum = () =>{
    const curriculum:ICurriculum = {
      id:"",
      lecture_date: new Date(),
      lecture_id:0,
      is_time:0,
      name:'',
      title:'',
      file_name:'',
      url:'',
      books:[],


    }
    return curriculum
  }

  return {newCurriculum}
}

export default useCurriculum