import {FC} from "react";
import {Button, ButtonProps} from "@mui/material";
import {styled} from "@mui/styles";
import {lightGreen} from "@mui/material/colors";
import Theme from '../../ThemeProvider';

const SButton = styled(Button)(({theme}) => ({
  textAlign: 'center',
  padding:'0.5em 2em',
  color:'#fff',
  borderRadius: 10,
  fontSize:"90%",
  backgroundColor: lightGreen['800'],
  '&:hover': {
    backgroundColor: lightGreen['700'],
    // color: lightGreen['00']
  }
}));
export type BaseButtonProps = Pick<ButtonProps, 'onClick'|'type'> & {
  label: string;
};
const DefaultButton: FC<BaseButtonProps> = ({onClick,label,type='button'}) => {
  return (
      <><SButton  type={type} onClick={onClick}>{label}</SButton></>
  )
}
export default DefaultButton