import {useEffect, useState} from 'react';
import http from '../Stores/http';
import {AxiosError} from 'axios';
import {ICurriculumDetail, ICurriculumDetailResponse} from '../Types/Response';


const useCurriculumDetail = () => {
  const [response, setResonse] = useState<ICurriculumDetail>({
    curriculum: undefined,
    onUsers: undefined,
    unUsers: undefined,
    error: null,
    loading: false,
    systemMessage:undefined
  })
  const [curriculumId,setCurriculumId] =useState<string>("0")
  const fetchRequest = (id:string) => {
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<ICurriculumDetailResponse | null>('curriculums/book/',{curriculum_id:id})
        .then((response) => {
          console.log("fetchRequest")
          console.log(response)
          setResonse({
            curriculum: response.data?.curriculum,
            onUsers:response.data?.onUsers,
            unUsers:response.data?.unUsers,//[{id:1,name:"aa","kana":"aaaaaa","biryday":"aaaaaa","goAt":6,"sex":"a","tel":"aaaaaaaa"}],
            error: null,
            loading: false,
            systemMessage: {text: "予約状況を読み込みました。",isDelete:true}
          })

        })
        .catch((error: AxiosError) => {
          console.log(error)
          // setResonse({... error:error,loading:false})

        })
  }

  const handlePersonCancel = (bookId:number) => {
    console.log("handlePersonCancel")
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<ICurriculumDetailResponse|null>('curriculums/cancel', {'book_id':bookId,curriculum_id:curriculumId})
      .then((response) => {
        setResonse(prevState =>(
          {...prevState,
            loading:false,
            onUsers:response.data?.onUsers,
            unUsers:response.data?.unUsers,
            systemMessage: {text: "予約をキャンセルしました。",isDelete:true}}))


      })
      .catch((error: AxiosError) => {
        console.log(error)
        setResonse((prevState) => ({...prevState,error:error,loading:false}))

      })

  }
  const handlePersonAdd = (userId:number) => {
    console.log("handlePersonAdd")
    setResonse(prevState => ({...prevState, loading: true}))
    http.post<ICurriculumDetailResponse|null>('curriculums/attend', {'user_id':userId,curriculum_id:curriculumId})
      .then((response) => {
        setResonse(prevState =>(
          {...prevState,
            loading:false,
            onUsers:response.data?.onUsers,
            unUsers:response.data?.unUsers,
            systemMessage: {text: "予約を追加しました。",isDelete:true}}))


      })
      .catch((error: AxiosError) => {
        console.log(error)
        setResonse((prevState) => ({...prevState,error:error,loading:false}))

      })
    console.log(response)

  }
  return {...response,setResonse,fetchRequest,setCurriculumId, handlePersonCancel,handlePersonAdd}
};
export default useCurriculumDetail;
