import {FC, useContext, useEffect, useState} from 'react';
import useFetchBooks from '../../Hooks/useFetchBooks'
import Config from '../../Types/config';
import {Alert, Box, Grid, Stack, Typography} from '@mui/material';
import DefaultButton from '../atom/Buttons/DefaultButton';
import useCalendar, {TWeeks} from '../../Hooks/useCalendar';
import {MessageContext} from '../MessageContext';
import BookCell from '../comm/BookCell';
import {useNavigate, useParams} from "react-router";
import BackdropLodding from '../comm/BackDrop';

interface ICurriculumParam {
  year: string
  month: string
}

const Books: FC = () => {
  const {year, month} = useParams() as ICurriculumParam

  const {
    loading,
    error,
    fetchRequest,
    curriculums,
    calendarDate,
    setCalendarDate,
    handleCalendarDown,
    handleCalendarUP
  } = useFetchBooks(year, month)
  const {createCurriculumCalendar} = useCalendar()
  const [weeks, setWeeks] = useState<TWeeks>([[]])
  const [bookCount, setBookCount] = useState<number>(0)
  const messageContext = useContext(MessageContext)
  const navigate = useNavigate();



  useEffect(() => fetchRequest(), [setCalendarDate])
  useEffect(() => {
    if (curriculums.length > 0) {
      messageContext.handleSetMessage('カリキュラムを読み込みました。')
      //dispCurrcilums()
      setWeeks(prevState => createCurriculumCalendar(curriculums))
      setBookCount(() => {
        const c = curriculums.filter(value => value.lecture_id > 0)
        return c.length
      })
    }
  }, [curriculums])

  useEffect(() => {
    if (loading) messageContext.handleSetMessage('')

  }, [loading])

  if (loading) return <><BackdropLodding showBackdrop={loading}/></>


  if(error){
      // setContextError("ssssssssss",{message:"tttttttW",name:"aaaaaaaa"})
    // navigate('/error/')
    return null

  }

  return (
    <>
      <Box sx={{my: 3}}>
        <Grid container alignItems="center">
          <Grid item sm={8} >
            <Typography variant={'h5'}>
              {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 予約状況
            </Typography>
          </Grid>
          <Grid item sm={4} justifyContent="center">
            <Stack justifyContent="flex-end" direction={{md:"row",xs:'row'}} alignItems="right" spacing={3}>

              <DefaultButton onClick={handleCalendarDown} label="前月"/>

              <DefaultButton onClick={handleCalendarUP} label="翌月"/>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {bookCount === 0 && (
          <Alert severity="info">カリキュラムが登録されていません。
          </Alert>
      )}
      {bookCount > 0 && (
          <table style={{width: '100%', border: '1px soild #eee', borderSpacing: '0'}}>
            {weeks.map((week, key) => (
                <tr>
                  {week.map((day, index) => (
                      <BookCell curritulum={day} currentMonth={calendarDate.getMonth()}/>

                  ))}
                </tr>
            ))}
          </table>)}
    </>
  )

}
export default Books