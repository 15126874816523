import {
  Box,
  List,
  Drawer, Divider,
} from '@mui/material';
import { Inbox, Mail } from '@mui/icons-material';
import {Link} from 'react-router-dom';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DateRangeSharpIcon from '@mui/icons-material/DateRangeSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from 'react-router';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const DropMemu = (props: Props) => {
  const {open,onClose} =props
  const navigate = useNavigate();
  const handleGotoPage = (url:string) =>{
    navigate(url)
    onClose()

  }
  const today = new Date()
  return (
    <Drawer open={open} onClose={onClose}>
      <Box sx={{ width: 250 }} role="presentation">
      <List>

        <ListItem button onClick={()=> handleGotoPage('/')}>
          <ListItemIcon >
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="ダッシュボード" />
        </ListItem>
        <ListItem button onClick={()=> handleGotoPage('/users')}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="利用者管理" />
        </ListItem>
        <ListItem button onClick={()=> handleGotoPage(`/books/${today.getFullYear()}/${today.getMonth() +  1}`)}>
          <ListItemIcon>
            <DateRangeSharpIcon />
          </ListItemIcon>
          <ListItemText primary="予約管理" />
        </ListItem>
        <ListItem button onClick={()=> handleGotoPage('/curriculums')}>
          <ListItemIcon>
            <FolderSharpIcon />
          </ListItemIcon>
          <ListItemText primary="カリキュラム管理" />
        </ListItem>
    </List>

{/*    <List>*/}
{/*      {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (*/}
{/*    <ListItem button key={text} onClick={props.onClose}>*/}
{/*  <ListItemIcon>*/}
{/*    {index % 2 === 0 ? <Inbox /> : <Mail />}*/}
{/*  </ListItemIcon>*/}
{/*  <ListItemText primary={text} />*/}
{/*  </ListItem>*/}
{/*))}*/}
{/*  </List>*/}
        <Divider />
  </Box>
  </Drawer>
);
};