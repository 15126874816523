import {FC, memo, useCallback, useState} from 'react';
import CellDay from '../atom/CellDay';
import Config from '../../Types/config';
import {makeStyles, styled} from '@mui/styles';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Theme,
  Typography
} from '@mui/material';
import {blue, green, lime, red} from '@mui/material/colors';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SecondButton from '../atom/Buttons/SecondButton';
import SpecialCurritulumEdit from '../Organisms/SpecialCurritulumEdit';
import {ICurriculum, ILectureMaster} from '../../Types/Response';
import CellDateHead from './CellDateHead';

type Props = {
  lecutureMaster: Array<ILectureMaster> | undefined,
  curriculum: ICurriculum,
  currentMonth: number,
  handleCheangeCurritulums: (cCurriculum: ICurriculum) => void
}


const CurriculumCell:FC<Props> =({lecutureMaster,curriculum,currentMonth,handleCheangeCurritulums}) =>{
  // console.log('CurriculumCell' + curritulum.lecture_date)
  const [isSpecialCurritulumEditWindow, setSpecialCurritulumEditWindow] = useState<boolean>(false)
  const handleCheangeCurritulum = (event: SelectChangeEvent) => {
    if (Number(event.target.value) === 7) {
      setSpecialCurritulumEditWindow(prevState => true)
      return null
    }
    handleCheangeCurritulums({...curriculum,lecture_id:Number(event.target.value)})
  }


  const handleCheangeIsTime = (e:React.SyntheticEvent,isTimeValue:boolean) => {
        console.log(isTimeValue)
        handleCheangeCurritulums({...curriculum,is_time:isTimeValue === true ? 1 : 0})
  }


  return(
    <>
      <CellDay mobileDisplay={currentMonth === curriculum.lecture_date.getMonth() ? 'block' : 'none'}>
        {currentMonth === curriculum.lecture_date.getMonth() ?
          <>

            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Stack direction={{sm:"column",xs:'row'}} spacing={{md:2,xs:4}}>
            <CellDateHead date={curriculum.lecture_date}/>
            <Select sx={{width: '100%',fontSize:"100/%",my:2}} name={(curriculum.lecture_date.getDate() - 1).toString()}
                    defaultValue={curriculum.lecture_id.toString()} onChange={handleCheangeCurritulum}>
              <MenuItem key={`m${(curriculum.lecture_date.getDate() - 1).toString()}`} sx={{}} value="0"></MenuItem>
              {lecutureMaster?.map((curritulum,i) => (
                <MenuItem key={`m${(curriculum.lecture_date.getDate() - 1).toString()}${i}`} sx={{}} value={curritulum.id.toString()}>{curritulum.name}</MenuItem>
              ))}
            </Select>

            {curriculum.lecture_id >1 && <FormControlLabel
              value={curriculum.is_time}
              control={<Switch sx={{color:lime['200']}} defaultChecked={curriculum.is_time > 0} />}
              label={<p style={{fontSize:"60%"}}>時間指定</p>}
              labelPlacement="start"
              onChange={(e:React.SyntheticEvent,isTimeValue:boolean) => {handleCheangeIsTime(e,isTimeValue)}  }
            />}

            {curriculum.lecture_id === 7 && <><Typography>{curriculum.title}</Typography>
              <SecondButton onClick={()=> setSpecialCurritulumEditWindow(prevState => true)} label="詳細"/>
            </>}
            <SpecialCurritulumEdit
              isOpen={isSpecialCurritulumEditWindow}
              curriculum={curriculum}
              onClose={setSpecialCurritulumEditWindow}
              handleCheangeCurritulums={handleCheangeCurritulums}
            />
                </Stack></Grid></Grid>
          </> : null
        }
      </CellDay>
    </>
  )
}
export default CurriculumCell