import {FC, useState} from 'react';
import Header from '../Organisms/Header';
import Footer from '../Organisms/Footer';
import {Box} from "@mui/material";
import {DropMemu} from '../Molecules/DropMenu';
import {ErrorProvider} from '../ErrorContext';

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const GenericTemplate:FC<GenericTemplateProps> = ({children}) => {
  const [dropMenuOpen, setDropMenurOpen] = useState(false);
  const toggleDropMenu = () => setDropMenurOpen(!dropMenuOpen);

  return (
    <>
      <ErrorProvider>
      <Header />
      <DropMemu open={dropMenuOpen} onClose={toggleDropMenu}/>
      <Box sx={{m: 2}}>
        {children}
      </Box>
      <Footer/>
      </ErrorProvider>
    </>
  )
}
export default GenericTemplate;