import {FC, memo, useCallback, useState} from 'react';
import CellDay from '../atom/CellDay';
import Config from '../../Types/config';
import {makeStyles, styled} from '@mui/styles';
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Theme,
  Typography
} from '@mui/material';
import {blue, green, red} from '@mui/material/colors';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SecondButton from '../atom/Buttons/SecondButton';
import SpecialCurritulumEdit from '../Organisms/SpecialCurritulumEdit';
import {ICurriculum, ILectureMaster} from '../../Types/Response';
import CellDateHead from './CellDateHead';
import {useNavigate} from 'react-router';

type Props = {
  curritulum: ICurriculum,
  currentMonth: number,
}


const BookCell: FC<Props> = ({curritulum, currentMonth}) => {
  // console.log('CurriculumCell' + curritulum.lecture_date)
  const navigate = useNavigate();

  return (

    <>
      <CellDay mobileDisplay={currentMonth === curritulum.lecture_date.getMonth() ? 'block' : 'none'} bookCount={curritulum.books.length}>
        {currentMonth === curritulum.lecture_date.getMonth() ?
            (
                <>
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                  <Stack direction={{sm:"column",xs:'row'}} spacing={{md:2,xs:4}}>
                    <CellDateHead date={curritulum.lecture_date}/>
                  <p>{curritulum.name}{curritulum.lecture_id === 7 && curritulum.title}</p>
                  {curritulum.lecture_id > 1 && (<>
                    <p>予約数：{curritulum.books.length}</p>

                    <p>
                      <SecondButton onClick={event => (navigate('/book/' + curritulum.id))} label="詳細"/>
                    </p>
              </>)}
                  </Stack>
                    </Grid></Grid>
            </>) : null
        }
      </CellDay>
    </>
  )
}
export default BookCell