import { FC } from "react"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {makeStyles} from '@mui/styles';
import {Theme, Typography} from '@mui/material';


export const useStyles = makeStyles((theme:Theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    textAlign:"center"

  },
  footer: {
    display:"flex",
    justifyContent:"center",
  }
}));

const Footer:FC = () =>{
  const classes = useStyles();

  return (
      <AppBar color="primary" className={classes.appBar}>
        <Toolbar className={classes.footer}>
          <Typography style={{fontSize:"30%"}} align="center">Copyright Man to Man Animo株式会社</Typography>
        </Toolbar>
      </AppBar>
  )
}
export default Footer