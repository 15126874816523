import {Dispatch, FC, MouseEventHandler, SetStateAction, useEffect, useState} from 'react';
import {User} from '../../Types/User';
import {ICurriculumDetail} from '../../Types/Response';
import {Alert, Box, Button, Grid, Input, Stack} from '@mui/material';
import {Link} from "react-router-dom";
import Info from "../comm/User/Info";
import DefaultButton from "../atom/Buttons/DefaultButton";
import SecondButton from "../atom/Buttons/SecondButton";

export type buttonProp = {
  label: string,
  func: (id: number) => void
}

type Prop = {
  users: User[],
  usersUpdate: Dispatch<SetStateAction<User[]>>
  button?: buttonProp
}
const UserSearch: FC<Prop> = (props) => {
  const {users,usersUpdate,button} = props

  const [rUser, setRUser] = useState<User[]>(users)
  const [keyword, setKeyword] = useState<string>("");
  const handleClear = () => {
    setKeyword(prevState => "")
    usersUpdate(prevState => (rUser))

  }
  const handleSearch = () => {
    if(rUser.length === 0) setRUser(prevState => users)
    console.log(keyword.length)
    if(keyword.length >0) {
      const nUsers = users.filter(value => {
        return String(value.name).toLowerCase().indexOf(keyword) > -1
    }
    )
      usersUpdate(prevState => (nUsers))
    }else {
      usersUpdate(prevState => (rUser))
    }
  }
  return (
      <>
        <Box sx={{marginY: '1em'}}>
          <Stack justifyContent="flex-end" direction="row" alignItems="center" spacing={3}>
            検索：<Input placeholder="名前" type="text" value={keyword} onChange={event => setKeyword(event.target.value)}/>
            <DefaultButton onClick={event => handleSearch()} label="検索 "/>
            <SecondButton onClick={event => handleClear()} label="クリア"/>

          </Stack>
        </Box>
        {users?.length === 0 && (
          <Alert sx={{mt:2,mb:4}} severity="info">利用者が見つかりませんでした。
          </Alert>
        )}

        <Box sx={{flexGrow: 1, my: 2}}>
          <Grid container direction="row" justifyContent="center" spacing={{xs: 1, md: 1}}>
            {users?.map((user, index) => (
                <Grid item key={index}>
                  <Info user={user} button={button ? button : undefined}/>
                </Grid>

                // </Link> |</p>
            ))}
          </Grid>
        </Box>
      </>
  )
}

export default UserSearch;