import {FC} from "react";
import {Button, ButtonProps} from "@mui/material";
import {styled} from "@mui/styles";
import {lightGreen, teal} from '@mui/material/colors';

const SButton = styled(Button)(({theme}) => ({
  textAlign: 'center',
  padding:'0.5em 2em',
  color:'#fff',
  borderRadius: 10,
  backgroundColor: teal['600'],
  '&:hover': {
    backgroundColor: teal['500']
  }
}));
export type BaseButtonProps = Pick<ButtonProps, 'onClick'> & {
  label: string;
};
const SecondButton: FC<BaseButtonProps> = (Props) => {
  return (
      <><SButton onClick={Props.onClick}>{Props.label}</SButton></>
  )
}
export default SecondButton