import {FC, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {Alert, Snackbar} from '@mui/material';

type IMessage = {
  content: string

}
const Message: FC<IMessage> = ({content}) => {
  console.log('create Message')
  const [isView,setView] = useState<boolean>(false)
  useEffect(() => {
    console.log('isVew')
    console.log(content)
   if(content.length > 0) setView(c => true);
  }, [content]);

  const handleClose = () => {
    setView(prevState => false)
  }
  return (
    <>
      {content.length > 0 ? (<Snackbar open={isView} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} >
      {content}
      </Alert>
    </Snackbar>) : ''}
    </>
  )
}


export default Message