import React, {createContext, Dispatch, SetStateAction, useCallback, useContext, useState} from 'react';
import {ErrorContextInterface} from '../Types/Error';
import Message from './comm/Message';
type ContextType ={
  message:string,
  handleSetMessage:(message:string) => void
  setMessage?:Dispatch<SetStateAction<string>>
}
export const MessageContext = createContext<ContextType>({
  message:'',
  handleSetMessage:()=>{}
})

export const MessageProvider: React.FC<object> = ({children}) => {

  const {message,handleSetMessage} = useMessage()


  return (
    <MessageContext.Provider
      value={{message,handleSetMessage}}
    >
      <Message content={message} />
      {children}
    </MessageContext.Provider>
  );
};
export const useMessage = () =>{
  const [message, setMessage] = useState<string>('');

  const handleSetMessage = useCallback (((message:string) =>{
  setMessage(prevState => message)
  }),[])
  const setContextError = (userMessage: string, error?: Error) => {
    setMessage(userMessage);
  };

  const setContextErrorDone = () => {
    setMessage('');
  };
  return  {
    message,
    handleSetMessage
  }
}